/* eslint-disable camelcase */

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { iconNext, iconBack } from 'assets';
import classnames from 'classnames';
import { ErrorContainer, Button } from 'components/UI';
import { ALL_ACCESS } from 'utils/constants';

import CONFIG, { isProduction } from 'utils/config';
import { AuthContext } from 'utils/context';
import useQuery from 'hooks/useQuery';
import BasicDetails from './BasicDetails';
import AuthLayout from '../common/AuthLayout';
import WorkDetails from './WorkDetails';
import SocialMediaDetails from './SocialMediaDetails';
import SuccessScreen from './SuccessScreen';
import Activate from '../Activate';
import ChooseTiers from './ChooseTiers';
import { gaEvents, getUTMParameters } from '../../../utils/analytics';

const LITE_MEMBER_TYPES = Object.freeze([
  'Musicians',
  'Music School Students',
  'Songwriters',
]);
const MIN_PRO_EXP_YEARS = 6;

const createValidationSchema = (checkAllAccessCriteria) => {
  const RegistrationSchema = [
    Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string().required('Password is required').min(8),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password must match')
        .required('Confirm password is required'),
      country: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
    }),
    Yup.object().shape({
      industry_since: Yup.number()
        .required('Required')
        .test(
          'validate all access experience',
          'Must have a minimum of 6 years of experience',
          (value) => {
            const currentYear = new Date().getFullYear();
            const hasAllAccessExp =
              value !== '' && currentYear - Number(value) >= MIN_PRO_EXP_YEARS;
            if (checkAllAccessCriteria) {
              return hasAllAccessExp;
            }
            return true;
          }
        ),
      memberTypes: Yup.array()
        .max(5, 'Maximum 5 member types allowed')
        .required('Required')
        .test(
          'validate all access member type',
          'Must have experience owning a music business',
          (value) => {
            const hasAllAccessMemberType = !!value.find(
              ({ label }) => !LITE_MEMBER_TYPES.includes(label)
            );
            if (checkAllAccessCriteria) {
              return hasAllAccessMemberType;
            }
            return true;
          }
        ),
      company: Yup.string().required('Required'),
      position: Yup.string().required('Required'),
      year_start: Yup.number()
        .required('Required')
        .when('year_end', {
          // allow to display dynamic year_end value, refer to MR 305
          is: ' ',
          then: Yup.number(),
          otherwise: Yup.number().max(
            Yup.ref('year_end'),
            'Invalid start year'
          ),
        }),
      year_end: Yup.mixed().required('Required'),
      company_url: Yup.string()
        .url('Invalid URL')
        .transform((currentValue) => {
          const doesNotStartWithHttp =
            currentValue &&
            !(
              currentValue.startsWith('http://') ||
              currentValue.startsWith('https://')
            );

          if (doesNotStartWithHttp) {
            return `http://${currentValue}`;
          }
          return currentValue;
        })
        .required('Required'),
    }),
    Yup.object().shape({
      facebook: Yup.string().url('Facebook Link must be a valid Url'),
      linked_in: Yup.string().url('LinkedIn Link must be a valid Url'),
      instagram: Yup.string().url('Instagram Link must be a valid Url'),
    }),
  ];
  return RegistrationSchema;
};

const steps = ['Choose Tier', 'Basic Details', 'Work Details', 'Social Media'];

const SignUp = () => {
  const { linkedInTag } = useContext(AuthContext);
  const dispatch = useDispatch();
  const {
    memberTier,
    referralCode: referral_code,
    signupCode: signup_code,
  } = useQuery();
  const sourceInfo = getUTMParameters();
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [hasTierError, showTierError] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [signupCode, setSignupCode] = useState(null);
  const [liteOnly, setLiteOnly] = useState(false);
  const [userFormValues, setUserFormValues] = useState(null);

  const memberTiers = useSelector(Selectors.memberTiersList);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.FETCH_MEMBER_TIERS_REQUEST,
      Actions.FETCH_MEMBER_TYPES_REQUEST,
    ])
  );
  const isCreateLoading = useSelector(
    Selectors.createLoadingSelector([Actions.CREATE_USER_REQUEST])
  );

  const selectedTierId = memberTiers.find(({ name }) => name === memberTier)
    ?.id;
  const allAccessId = memberTiers.find(({ name }) => name === ALL_ACCESS)?.id;
  const checkAllAccessCriteria = selectedTier === allAccessId;
  const validationSchema = createValidationSchema(checkAllAccessCriteria);
  const currentValidationSchema = validationSchema[activeStep - 1];

  const isLastStep = activeStep === steps.length - 1;
  const showNextScreen = () => setActiveStep((currentStep) => currentStep + 1);
  const showPrevScreen = () =>
    setActiveStep((currentStep) => Math.max(currentStep - 1, 0));
  const isFreeTierSelected = selectedTier
    ? memberTiers.find(({ id }) => id === selectedTier)?.is_free_tier
    : false;

  useEffect(() => {
    dispatch(Actions.fetchCountryOptions());
    dispatch(Actions.fetchMemberTypes());
    dispatch(Actions.fetchMemberTiers());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    if (selectedTierId) {
      showTierError(false);
      setSelectedTier(selectedTierId);
      setActiveStep(1);
    }
  }, [selectedTierId]);

  useEffect(() => {
    if (referral_code) {
      setReferralCode(referral_code);
    }
  }, [referral_code]);

  useEffect(() => {
    if (signup_code) {
      setSignupCode(signup_code);
    }
  }, [signup_code]);

  const getPrice = (tier, interval) =>
    Number(tier?.prices?.find((price) => price.interval === interval)?.amount);

  const getMinPrice = (prices) =>
    prices.length ? Math.min(...prices.map(({ amount }) => amount)) : 0;

  function handleOnSubmit(formValues, actions) {
    setError(null);
    const userDetails = {
      ...formValues,
      referral_code: referralCode,
      signup_code: signupCode,
      member_tier_id: selectedTier,
      member_types: formValues?.memberTypes?.map(({ id, value }) => ({
        id,
        name: value,
      })),
      source: sourceInfo?.source,
      content: sourceInfo?.content,
      term: sourceInfo?.term,
      medium: sourceInfo?.medium,
      campaign: sourceInfo?.campaign,
    };

    if (activeStep === 1)
      return dispatch(
        Actions.validateEmail(formValues.email, {
          success: () => {
            actions.setTouched({});
            actions.setSubmitting(false);
            showNextScreen();
          },
          failure: setError,
        })
      );

    if (isLastStep && !selectedTier) return showTierError(true);

    actions.setTouched({});
    actions.setSubmitting(false);
    setUserFormValues(userDetails);

    if (isFreeTierSelected && isLastStep) {
      if (isProduction && linkedInTag) {
        linkedInTag.track(CONFIG.LINKEDIN_FREE_TIER);
      }

      return dispatch(
        Actions.createUser(userDetails, {
          success: () => {
            setActiveStep(steps.length + 1);
            gaEvents.conversion(memberTier);
          },
          failure: (e) => {
            setActiveStep(0);
            const { error } = e.response.data;
            setError(
              typeof error === 'string'
                ? error
                : 'An error occurred, please try again later'
            );
          },
        })
      );
    }

    showNextScreen();
  }

  const createUser = (subscriptionParams) => {
    const userDetails = {
      ...userFormValues,
      referral_code: referralCode,
      signup_code: signupCode,
      member_tier_id: selectedTier,
      member_types: userFormValues?.member_types?.map(({ id, value }) => ({
        id,
        name: value,
      })),
      source: sourceInfo?.source,
      content: sourceInfo?.content,
      term: sourceInfo?.term,
      medium: sourceInfo?.medium,
      campaign: sourceInfo?.campaign,
    };

    dispatch(
      Actions.createUser(userDetails, {
        success: () => {
          dispatch(Actions.createSubscription(subscriptionParams));
          gaEvents.conversion(memberTier);
          showNextScreen();
        },
        failure: (e) => {
          setActiveStep(0);
          const { error } = e.response.data;
          setError(
            typeof error === 'string'
              ? error
              : 'An error occurred, please try again later'
          );
        },
      })
    );
  };

  if (activeStep === 0) {
    return (
      <ChooseTiers
        memberTiers={
          liteOnly
            ? memberTiers.filter(({ name }) => name !== ALL_ACCESS)
            : memberTiers
        }
        selectedTier={selectedTier}
        setLiteOnly={setLiteOnly}
        liteOnly={liteOnly}
        getPrice={getPrice}
        getMinPrice={getMinPrice}
        showNextScreen={showNextScreen}
      />
    );
  }

  function renderStepContent(step) {
    switch (step) {
      case 1:
        return (
          <BasicDetails
            memberTier={memberTiers.find(({ id }) => id === selectedTier)}
            getPrice={getPrice}
            showPrevScreen={showPrevScreen}
          />
        );
      case 2:
        return <WorkDetails />;
      case 3:
        return (
          <SocialMediaDetails
            signupCode={signupCode}
            hasTierError={hasTierError}
            isLoading={isCreateLoading}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  const FormButtons = () => (
    <div className="flex justify-between items-center">
      <button
        type="button"
        className={classnames(
          'uppercase text-dusty hover:text-easter flex focus:outline-none',
          { invisible: activeStep <= 0 }
        )}
        onClick={() => {
          showPrevScreen();
          setError(null);
        }}
      >
        <img alt="check" src={iconBack.default} className="h-4 mt-1 mr-4" />
        Back
      </button>
      {isLastStep ? (
        <Button
          className="max-w-80"
          label="APPLY FOR MEMBERSHIP"
          type="submit"
        />
      ) : (
        <button
          type="submit"
          className="uppercase text-dusty hover:text-easter flex focus:outline-none"
        >
          Continue
          <img alt="check" src={iconNext.default} className="h-4 mt-1 ml-4" />
        </button>
      )}
    </div>
  );

  if (activeStep === steps.length)
    return (
      <Activate
        selectedTier={selectedTier}
        navigateBack={showPrevScreen}
        createUser={createUser}
        email={userFormValues.email}
        liteOnly={liteOnly}
        referralCode={referralCode}
      />
    );

  return (
    <AuthLayout
      progress={activeStep}
      isLoading={isLoading}
      disableScroll={activeStep === 1}
    >
      {activeStep === steps.length + 1 ? (
        <SuccessScreen />
      ) : (
        <Formik
          initialValues={
            userFormValues
              ? { ...userFormValues }
              : {
                  first_name: '',
                  last_name: '',
                  email: '',
                  country: '',
                  city: '',
                  industry_since: '',
                  memberTypes: [],
                  company: '',
                  position: '',
                  year_start: '',
                  year_end: null,
                  company_url: '',
                  facebook: '',
                  linked_in: '',
                  instagram: '',
                  member_tier_id: null,
                  password: '',
                  confirmPassword: '',
                }
          }
          validationSchema={currentValidationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              {renderStepContent(activeStep, values)}
              <ErrorContainer error={error} className="mb-4" />
              <FormButtons />
            </form>
          )}
        </Formik>
      )}
    </AuthLayout>
  );
};

SignUp.propTypes = {};

export default SignUp;
