import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'actions';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { iconDate, iconLocation } from 'assets';
import { Button, Card, Picture, ImageFilter, PageMeta } from 'components/UI';
import {
  displayDayDateFormat,
  displaySubscriptionDateFormat,
} from 'utils/datetime';
import { urlHandler } from 'utils/helper';
import notify from 'utils/notification';
import { AuthContext } from 'utils/context';
import { ALL_MEMBERS, PREMIUM_ALL_ACCESS } from 'utils/constants';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { BackgroundSection } from '../LandingPage/common';
import MemberAccessBadge, { memberAccessMap } from './MemberAccessBadge';

const PerkViewCard = ({ perk }) => {
  const {
    id,
    name,
    summary,
    description,
    member_access: memberAccess,
    city,
    redemption_link: link,
    redemption_limit: redemptionLimit,
    redeemed_count: redeemedCount,
    promo_code: promo,
    image_url: img,
    start_date: startDate,
    end_date: expiry,
  } = perk;
  const dispatch = useDispatch();
  const { isPro, isLite } = useContext(AuthContext);
  const canRedeemPerk =
    isPro ||
    memberAccess === ALL_MEMBERS ||
    (isLite && memberAccess === PREMIUM_ALL_ACCESS);

  const RedeemSection = () => {
    const redeemCallbacks = {
      success: () => notify('Perk redeemed'),
      fail: () => notify('Failed to redeem perk', null, 'danger'),
    };
    const redeemPerk = () => dispatch(Actions.redeemPerk(id, redeemCallbacks));
    const Content = () => {
      if (promo)
        return (
          <div className="flex flex-col items-center w-full justify-center font-semibold">
            <p className="text-sm text-dusty mb-3">PROMO CODE</p>
            {link ? (
              <a
                href={link.slice(0, 4) === 'http' ? link : `https://${link}`}
                className="text-lg text-sigmaBlue-dark tracking-widest"
                target="_blank"
                rel="noreferrer"
              >
                {promo}
              </a>
            ) : (
              <p className="text-lg tracking-widest">{promo}</p>
            )}
          </div>
        );

      if (link)
        return (
          <div className="flex flex-col items-center w-full justify-center font-semibold">
            <p className="text-sm text-dusty mb-3">REDEMPTION LINK</p>
            <a
              href={link.slice(0, 4) === 'http' ? link : `https://${link}`}
              className="text-lg text-sigmaBlue-dark"
              target="_blank"
              rel="noreferrer"
            >
              Click here to redeem!
            </a>
          </div>
        );

      if (!canRedeemPerk) {
        return (
          <Tooltip
            interactive
            title={
              <div className="text-xs">
                Unfortunately you don&apos;t have access to redeem this perk.
                <Link to="/subscription" className="font-semibold text-easter">
                  {` Upgrade here `}
                </Link>
                if you would like to join us!
              </div>
            }
            placement="top"
            arrow
          >
            <div>
              <Button
                label={`${memberAccessMap[memberAccess]} Members Only`}
                className="w-full text-white bg-gray-400"
                disabled
              />
            </div>
          </Tooltip>
        );
      }

      if (redemptionLimit && redeemedCount >= redemptionLimit)
        return <Button label="Fully Redeemed" className="w-64 h-12" disabled />;

      return (
        <>
          <Button
            label="REDEEM PERK"
            className="w-64 h-12"
            onClick={redeemPerk}
          />
          {redemptionLimit && (
            <p className="text-boulder font-semibold mt-5">
              {`${redemptionLimit - redeemedCount} remaining`}
            </p>
          )}
        </>
      );
    };

    return (
      <div className="flex flex-col p-5 border-gray-400 border rounded items-center mb-10">
        <Content />
      </div>
    );
  };

  return (
    <>
      <PageMeta title={name} ogImg={img} />
      <Card className="h-full relative my-10">
        <BackgroundSection
          backgroundImage={`url(${urlHandler(img)})`}
          className="relative bg-no-repeat bg-cover bg-center h-140 rounded-lg"
        >
          <ImageFilter className="rounded-t-lg" />
          <div className="flex flex-col text-white justify-end h-full relative">
            <div className="flex flex-col m-10">
              <MemberAccessBadge memberAccess={memberAccess} md />
              <div className="lg:text-6xl text-xl font-chap">{name}</div>
              <div className="lg:text-lg sm:text-md">{summary}</div>
              <div className="mt-5 text-sm sm:text-base">
                {`Expires ${displaySubscriptionDateFormat(expiry)}`}
              </div>
            </div>
          </div>
        </BackgroundSection>
        <div className="pt-10 sm:px-8 px-2 flex flex-wrap lg:flex-no-wrap">
          <div className="flex flex-col lg:w-2/3 w-full">
            <div className="font-lynstone text-lg font-semibold">
              ABOUT THIS PERK
            </div>
            <div className="my-5">{ReactHtmlParser(description)}</div>
          </div>
          <div className="lg:w-1/3 w-full sm:pl-5 mb-30">
            <RedeemSection />
            <div className="mt-2 pl-5 flex flex-col max-w-xs m-auto">
              <div className="uppercase text-gray-500 text-sm">From</div>
              <div className="flex mt-4 items-center">
                <Picture src={iconDate} className="w-5 mr-4" />
                <div>{displayDayDateFormat(startDate)}</div>
              </div>
              <div className="uppercase text-gray-500 text-sm mt-6">Until</div>
              <div className="flex mt-4 items-center">
                <Picture src={iconDate} className="w-5 mr-4" />
                <div>{displayDayDateFormat(expiry)}</div>
              </div>
              {city && (
                <>
                  <div className="uppercase text-gray-500 text-sm mt-6">
                    Location
                  </div>
                  <div className="flex mt-4 items-center">
                    <Picture src={iconLocation} className="w-4 mr-4" />
                    <div>{city}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

PerkViewCard.propTypes = {
  perk: PropTypes.object.isRequired,
};

export default PerkViewCard;
