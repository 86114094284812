import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/UI';
import { useNavigate } from 'react-router-dom';

const TierCard = ({ tier }) => {
  const { name, prices } = tier;
  const navigate = useNavigate();
  const monthPrice = prices.find(({ interval }) => interval === 'month');
  const yearPrice = prices.find(({ interval }) => interval === 'year');

  const Title = () => {
    return (
      <div className="flex items-center justify-between px-3 py-1 border-b-2">
        <p className="font-chap text-md">{name}</p>
        <Button
          label="Upgrade"
          onClick={() =>
            navigate('/subscription/update', {
              state: { tierToUpdate: name },
            })
          }
          className="w-30"
          isPill
        />
      </div>
    );
  };

  const Content = () => {
    return (
      <div className="py-2 px-3">
        <p className="text-sm text-martini text-left">
          Upgrade now for the full Gig Life Pro experience - invites,
          information and access
        </p>
        <div className="flex flex-col items-end">
          <div className="text-easter">{`${monthPrice.currency} ${Number(
            monthPrice.amount
          )}/mo`}</div>
          <div className="text-silver text-sm">{`${yearPrice.currency} ${Number(
            yearPrice.amount
          )}/yr`}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="rounded-lg border border-alto">
      <Title />
      <Content />
    </div>
  );
};

TierCard.propTypes = {
  tier: PropTypes.object.isRequired,
};

export default TierCard;
