import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList as List } from 'react-window';
import { iconSelectDropDown, iconSelectDropDownActive } from 'assets';
import { config } from 'utils/tailwind';
import InfiniteLoader from 'react-window-infinite-loader';

const {
  colors: { easter, white },
  gradients: { 'easter-bostonBlue': easterBostonBlue },
} = config.theme;

const MenuList = ({ children, selectProps }) => {
  const { listHeight, isInfinite, infiniteProps } = selectProps;
  if (!Array.isArray(children)) children = [];
  const rows = children || [];

  const rowRenderer = ({ index, style }) => (
    <div style={style} className="text-sm sm:text-base truncate">
      {rows[index]}
    </div>
  );

  rowRenderer.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  const maxHeight = `${children.length * 36}px`;

  if (isInfinite) {
    const { isItemLoaded, itemCount, loadMoreItems } = infiniteProps;
    // TODO: Fixed infinite loading

    return (
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <List
            itemCount={itemCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
          />
        )}
      </InfiniteLoader>
    );
  }

  return (
    <List
      style={{ maxHeight }}
      className="w-full"
      height={listHeight}
      itemSize={36}
      itemCount={rows.length}
    >
      {rowRenderer}
    </List>
  );
};

MenuList.propTypes = {
  children: PropTypes.array.isRequired,
  selectProps: PropTypes.any.isRequired,
};

const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  const icon = (
    <img
      className="h-2 w-4 mr-3"
      src={iconSelectDropDown.default}
      alt="Dropdown icon"
    />
  );
  const iconActive = (
    <img
      className="h-2 w-4 mr-3"
      src={iconSelectDropDownActive.default}
      alt="Dropdown Active icon"
    />
  );
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? iconActive : icon}
      </components.DropdownIndicator>
    )
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

const customStyles = {
  option: (base) => ({
    ...base,
    border: '0px',
  }),
  control: (base) => ({
    ...base,
    border: '0px',
    boxShadow: '0 5px 15px 0 rgba(14,92,164,0.3)',
    fontSize: '16px',
    paddingLeft: '13px',
    borderRadius: '5px',
    minWidth: '200px',
    cursor: 'pointer',
    minHeight: '50px',
  }),
  menuList: (base) => ({
    ...base,
    fontWeight: '300',
    minWidth: '200px',
  }),
  menu: (base) => ({
    ...base,
    fontWeight: '300',
    minWidth: '200px',
    borderRadius: '5px',
    zIndex: 11,
  }),
  clearIndicator: (base) => ({
    ...base,
    color: easter,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundImage: `linear-gradient(${easterBostonBlue[2]}deg, ${easterBostonBlue[1]}, ${easterBostonBlue[0]})`,
    borderRadius: '3px',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: white,
    paddingLeft: '15px',
    paddingRight: '10px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: white,
    marginTop: '5px',
    marginBottom: '5px',
    borderLeft: `1px solid ${white}`,
    '&:hover': {
      backgroundColor: 'unset',
    },
  }),
  valueContainer: (base) => ({
    ...base,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
};

const Select = (props) => {
  const {
    defaultValue,
    onChange,
    className,
    options,
    value,
    creatable,
    isMulti,
  } = props;
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption || (isMulti ? [] : ''));
  };

  const commonProps = {
    ...props,
    components: { MenuList, DropdownIndicator },
    className,
    onChange: handleChange,
    options,
    value: value || selectedOption,
    styles: customStyles,
    theme: (theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary: '#47bad4',
        primary25: '#c9f5ff',
        neutral80: '#7a7a7a',
        primary50: '#a3ddea',
      },
    }),
  };

  if (creatable) return <CreatableSelect {...commonProps} />;

  return <ReactSelect {...commonProps} />;
};

Select.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  listHeight: PropTypes.number,
  creatable: PropTypes.bool,
  isInfinite: PropTypes.bool,
  infiniteProps: PropTypes.object,
  isMulti: PropTypes.bool,
};

Select.defaultProps = {
  listHeight: 200,
  className: '',
  defaultValue: null,
  value: null,
  onChange: () => {},
  creatable: false,
  isInfinite: false,
  infiniteProps: {},
  isMulti: false,
};

export default Select;
